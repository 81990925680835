loadjs.ready(['jquery', 'core'], function() {
    var $connectModal = $('#connect-modal');
    var $connectVerifyModal = $('#connect-verify-modal');
    var fetchFragment = function (fragment, payload, callback) {
        preloader(1);
        $.get(routes.auth.fragments.replace('_fragment_', fragment), payload, function (response) {
            var $section = $connectModal.find('section');
            $section.html(response);
            $section.find('form.needs-validation').formValidate();
            $section.find('.icon').icon();

            preloader(0);
            if (fragment === 'start') {
                $connectModal.find('.back').prop('disabled', true).css({opacity: 0});
            } else {
                $connectModal.find('.back').prop('disabled', false).css({opacity: 1});
            }

            if (_isAuth) {
                $connectModal.find('.close').prop('disabled', true).css({opacity: 0});
            }

            if ($connectModal.data('email')) {
                var $email = $connectModal.find('input[type="email"]');
                if (!$email.val()) {
                    $email.val($connectModal.data('email')).closest('form').submit();
                }
            }

            if (typeof callback === 'function') {
                callback()
            }
        });
    };
    var showVerifyModal = function(email) {
        if (!$connectVerifyModal.is('.in')) {
            $connectVerifyModal.modal('show');
        }
        $.get(routes.auth.verify, {email: email}, function (response) {
            $connectVerifyModal.find('section').html(response);
            $connectVerifyModal.find('form.needs-validation').formValidate();
        });
    };
    var postAuthCallback = function($modal, response) {
        var $success = $modal.find('.form-success');
        var cb = function() {
            preloader(1);
            if ($connectModal.data('continue')) {
                location.href = $connectModal.data('continue');
            } else if (response && response.redirect) {
                location.href = response.redirect;
            } else {
                location.reload();
            }
        }

        $modal.find('.hide-on-success').hide();
        $modal.find('.form-success').removeAttr('hidden');

        if ($success.length) {
            setTimeout(cb, 4000);
        } else {
            cb();
        }

    }
    var storageChangeCallback = function(e) {
        var d = null;
        if (e.key !== 'oauth_state') {
            return;
        }
        if (e.newValue) {
            d = JSON.parse(e.newValue)
        }

        if (d === null) {
            return;
        }

        window.setOauthState(d);
    }
    window.setOauthState = function(state) {
        window.removeEventListener('storage', storageChangeCallback);
        try {
            localStorage.removeItem('oauth_state');
        } catch (e) {}

        if (typeof state.auth !== 'undefined') {
            if (state.auth === true) {
                postAuthCallback($connectModal, state);
            } else {
                //err
            }
        } else if (state.verify) {
            showVerifyModal(state.verify);
        } else {
            fetchFragment('register_oauth', state)
        }
    }

    $connectModal
        .on('modal:show', function() {
            fetchFragment('start', {});
        })
        .on('success', 'form', function(e, response) {
            var $form = $(this);

            if ($form.is('.auth-check')) {
                fetchFragment(
                    response.status ? 'login' : 'register',
                    {'email': $form.find('input[name="email"]').val()}
                );
            } else if ($form.is('.auth-login') || $form.is('.oauth-register') || $form.is('.auth-register')) {
                if (response.verify) {
                    showVerifyModal(response.verify);
                    if ($form.is('.oauth-register') || $form.is('.auth-register')) {
                        fetchFragment(
                            $form.is('.oauth-register') ? 'start' : 'login',
                            {'email': response.verify}
                        );
                    }

                } else {
                    postAuthCallback($connectModal, response);
                }
            }
        })
        .on('click', '.back', function() {
            fetchFragment('start', {});
            return false;
        })
        .on('click', '.continue-oauth', function() {
            var $el = $(this);

            if ($el.data('provider') === 'google') {
                let rules = [
                    'WebView',
                    '(iPhone|iPod|iPad)(?!.*Safari)',
                    'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
                    'Linux; U; Android'
                ];
                let webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig')
                let isWv = !!window.navigator.userAgent.match(webviewRegExp);
                if (isWv && new Date() >= new Date('2023-07-23')) {
                    window.showError('Pentru a te putea autentifica prin Google, te rugăm să deschizi această pagină din browser.')
                    return false;
                }
            }
            window.addEventListener('storage', storageChangeCallback);
            window.open($el.attr('href'), '_blank');
            return false;
        })
        .on('click', '.forgot-password', function () {
            var $el = $(this);
            var email = $el.closest('form').find('input[name="email"]').val();
            preloader(1);
            $.post(routes.auth.forgotPassword, {email: email}, function (response) {
                preloader(0);
                $el.hide();
                if (response.success) {
                    $('#password-reset-success').find('span').html(email).end().removeAttr('hidden')
                } else {
                    $('#password-reset-fail').removeAttr('hidden');
                }
            }, 'json')
            return false;
        })

    $connectVerifyModal
        .on('success', 'form', function(e, response) {
            postAuthCallback($connectVerifyModal, response);
        })
        .on('click', '.resend-code', function() {
            var $el = $(this);
            var email = $el.closest('form').find('input[name="email"]').val();

            preloader(1);
            $.post(routes.auth.verifyResend, {email: email}, function (response) {
                $el.hide();
                preloader(0);
                if (response.success) {
                    $('#code-resend-success').removeAttr('hidden');
                } else {
                    $('#code-resend-fail').removeAttr('hidden');
                }
            }, 'json')
            return false;
        })
})
